.navbar {
  display: block;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #343a40;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.navbar__header {
  color: #f8f9fa;
  padding-left: 16px;
}
