.card-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
}

li:hover {
  background-color: #d4670e;
}
