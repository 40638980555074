.hero {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  color: #f8f9fa;
  margin-top: 60px;
}

.hero__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 24px;
}

.hero__title {
  text-align: center;
  font-family: "Roboto";
  font-size: 40px;
  font-weight: 500;
}

.hero__pic {
  width: 100%;
  max-height: 600px;
  border-radius: 5px;
  object-fit: cover;
}

.hero__img {
  width: 100%;
}

.hero__btn-wrapper {
  display: flex;
  justify-content: center;
}

.hero__btn {
  width: fit-content;
  padding: 12px 16px;
  background-color: #f16f05;
  font-size: 20px;
  font-weight: 600;
  color: #f8f9fa;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.hero__btn:hover {
  background-color: rgb(111, 98, 5);
}

.hero__text {
  text-align: center;
  font-weight: 300;
  font-size: 17px;
}

@media screen and (min-width: 768px) {
  .hero {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 500;
    color: #f8f9fa;
    padding: 100px;
  }

  .hero__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
  }

  .hero__title {
    font-family: "Roboto";
    font-size: 50px;
    text-align: left;
  }

  .hero__text {
    font-weight: 300;
    font-size: 17px;
    text-align: left;
  }

  .hero__pic {
    width: 100%;
    border-radius: 5px;
  }

  .hero__btn {
    width: 145px;
    height: 54px;
    background-color: #f16f05;
    font-size: 25px;
    font-weight: 600;
    color: #f8f9fa;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }

  .hero__tag {
    width: fit-content;
  }

  .hero__btn-wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .hero__btn:hover {
    background-color: rgb(111, 98, 5);
  }
}
