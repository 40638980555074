.card__img {
  padding: 10px;
  border-radius: 10px;
}
.card__logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  -webkit-box-shadow: 0px 0px 39px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 39px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 39px -7px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
}
