.App {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  background-image: linear-gradient(
    to bottom,
    rgba(52, 58, 64, 1),
    rgba(52, 58, 64, 0.9),
    rgba(52, 58, 64, 0.6)
  );
}
